import React from 'react'
import { Breadcrumbs as HdsBreadcrumbs, Link } from '@postenbring/hedwig-react'

export const Breadcrumbs = ({ crumbs }) => {


    const breadcrumbItems = crumbs.map((breadcrumb, index) => {
        return (
            <li>
                {index < crumbs.length - 1 ? (
                    <Link href={breadcrumb.href}>
                        {breadcrumb.title}
                    </Link>
                ) : (
                    breadcrumb.title
                )}
            </li>
        )
    })

    return (
        <HdsBreadcrumbs>
            {breadcrumbItems}
        </HdsBreadcrumbs>
    )
}
